import 'regenerator-runtime/runtime'

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { SnackbarProvider } from 'notistack'
import { publicProvider } from 'wagmi/providers/public'


import Route from './route'
import {
    WagmiConfig,
    createClient,
} from 'wagmi'
import './modules/firebase'
import { getAnalytics } from 'firebase/analytics'
import { getDefaultProvider } from 'ethers'


getAnalytics()


const client = createClient({
    autoConnect: true,
    provider: getDefaultProvider(),
})

const App = () => (
    <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <WagmiConfig client={client}>
            <Route />
        </WagmiConfig>
    </SnackbarProvider>
)

const root = createRoot(document.getElementById('root')!)
root.render(<App />)

import * as React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import * as Main from '../pages/Main'

export const AppRoute = (): React.ReactElement => (
    <Router>
        <Routes>
            <Route path="/" element={<Main.Component />} />
        </Routes>
    </Router>
)
export default AppRoute

import * as React from 'react'
import { styled } from '@mui/material/styles'

type Props = {
    children: React.ReactNode
}

export const Component = ({ children, ...props }: Props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Wrap {...props}>
            <Inner>{children}</Inner>
        </Wrap>
    )
}

const Wrap = styled('div')`
    color: #ffffff;
    background-color: #ffffff;
`

const Inner = styled('div')``

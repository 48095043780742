import * as React from "react";
import { styled } from "@mui/material/styles";

type Props = {
  address?: string;
};

export const Component = (props: Props) => {
  const [userIcon] = React.useMemo(() => {
    if (props?.address) {
      return [
        <Address>
          {props.address.slice(0, 6)}...{props.address.slice(-4)}
        </Address>,
      ];
    } else {
      return [null];
    }
  }, [props?.address]);
  return (
    <Wrap>
      <HeaderContents>
        <Title>TICK3T</Title>
      </HeaderContents>
      <HeaderContents>{userIcon}</HeaderContents>
    </Wrap>
  );
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const Wrap = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderContents = styled("div")`
  display: flex;
`;

const Address = styled("div")`
  background-color: white;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  color: ${(props) => props.theme.palette.text.secondary};
`;
const Title = styled("div")`
  padding-left: 5px;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  font-family: Monospace;
  color: rgb(121, 121, 121);
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

import * as React from 'react'
import * as Layout from '../layout'
import { styled } from '@mui/material/styles'
import {
    Box,
    Backdrop,
    CircularProgress,
    Stack,
    TextField
} from '@mui/material'
import { Button } from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'

type Props = {
    address?: string
    loading: boolean
    qr?: string
    ens: string
    onChangeENS: (event: React.ChangeEvent<HTMLInputElement>) => void
    onSubmit: () => void
}

export const Component = (props: Props) => {
    const QRorButton = React.useMemo(() => {
        if (!props.qr)
            return (
                <ButtonWrapper>
                    <Button
                        variant="outlined"
                        onClick={props.onSubmit}
                        fullWidth
                    >
                        Create a ticket
                    </Button>
                </ButtonWrapper>
            )
        return (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <QRCodeWrapper
                    sx={{
                        color: 'white',
                        mt: 2,
                        borderRadius: 1,
                        textAlign: 'center',
                        fontSize: '1rem',
                        fontWeight: '700'
                    }}
                >
                    <QRCodeSVG value={props.qr} size={320} />
                </QRCodeWrapper>
            </Stack>
        )
    }, [props.onSubmit, props.qr])

    return (
        <Layout.Main address={props.address}>
            <ContainerItems m={2}>
                <StyledBackDrop open={props.loading}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <TitleBackdrop>Signing message..</TitleBackdrop>
                        <CircularProgress color="inherit" />
                    </Stack>
                </StyledBackDrop>
                <Title>NFT Ticketing Service</Title>
                <Sub1Title>🌇 Event</Sub1Title>
                <Sub3Title>CryptoBar P2P</Sub3Title>
                <Sub1Title>🐤 Description</Sub1Title>
                <Sub3Title>Open the gate with your ENS!</Sub3Title>
                <Sub1Title>💎 Your ENS</Sub1Title>
                <TextField
                    fullWidth={true}
                    value={props.ens}
                    onChange={props.onChangeENS}
                />

                {QRorButton}
            </ContainerItems>
        </Layout.Main>
    )
}

const ContainerItems = styled(Box)``

const StyledBackDrop = styled(Backdrop)`
    z-index: 1001;
    color: '#fff';
`

const TitleBackdrop = styled('div')`
    z-index: 1002;
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
`

const Title = styled('div')`
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 20px;
    text-align: center;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

const Sub1Title = styled('div')`
    font-weight: bold;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const Sub3Title = styled('div')`
    font-size: 12px;
    line-height: 12px;
    margin-top: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const ButtonWrapper = styled('div')`
    text-align: center;
    margin-top: 20px;
`

const QRCodeWrapper = styled(Box)``

import * as React from 'react'
import * as MainTemplate from '../templates/Main'
import * as SignInTemplate from '../templates/SignIn'
import axios from 'axios'
import { useAccount, useConnect, useEnsName, useSignMessage } from 'wagmi'
import { useSnackbar } from 'notistack'
import * as ethers from 'ethers'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ethereum: any | undefined // metamask injects global ethereum
    }
}

const apiClient = axios.create({
    baseURL: 'https://tick3t-api-v2-sl6bqje65q-uw.a.run.app/',
    headers: {
        Authorization:
            'Bearer 9b2337fee411655e5aa46163ead9477b8a91cb5e54f85b9b298205196983d1b1'
    }
})

export const Component = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { connector, isConnected, address } = useAccount()
    const { connect, connectors } = useConnect()

    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [ens, setENS] = React.useState<string>('')
    const [qr, setQr] = React.useState<string>('')

    const { signMessage } = useSignMessage({
        async onSuccess(data, variables) {
            let responseData = (
                await apiClient.post('/issue', {
                    signature: data,
                    message: JSON.parse(variables.message as string)
                })
            ).data

            setLoading(false)
            setQr(JSON.stringify(responseData))

            responseData = (await apiClient.post('/verify', responseData)).data
            console.log(responseData)
        },
        onError() {
            setLoading(false)
            enqueueSnackbar(
                'MetaMask Message Signature: User denied message signature.',
                { variant: 'error' }
            )
        }
    })

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    React.useEffect(() => {
        if (width <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    React.useEffect(() => {
        if (address) {
            // @ts-ignore
            let provider = new ethers.providers.Web3Provider(window.ethereum, 'homestead');
            provider.lookupAddress(address)
                .then((ens) => {
                    console.log("ENS:", ens)
                    if (ens) setENS(ens)
                })
        } else {
            setENS('')
        }
    }, [address])

    const onChangeENS = (event: React.ChangeEvent<HTMLInputElement>) => {
        setENS(event.target.value)
    }

    const onClickConnect = () => setDialogOpen(true)

    const onSelectConnectionOrDialogClose = React.useCallback(
        (selectedConnectionType: 'MetaMask' | 'WalletConnect' | '') => {
            setDialogOpen(false)
            if (selectedConnectionType === '') return
            if (selectedConnectionType === 'MetaMask') {
                if (isMobile) {
                    if (window.ethereum === undefined) {
                        if (window.confirm('Open in the MetaMask app?')) {
                            const baseUrl = 'dapp://'
                            // eslint-disable-next-line
                            location.href = `${baseUrl}${location.hostname}${location.pathname}${location.search}`
                            return
                        }
                        enqueueSnackbar('MetaMask Should Be Installed', {
                            variant: 'error'
                        })
                        return
                    }
                }
                connect({ connector: connectors[0] })
            }
            if (selectedConnectionType === 'WalletConnect') {
                connect({ connector: connectors[1] })
            }
        },
        [connect, connectors, enqueueSnackbar, isMobile]
    )

    React.useEffect(() => {
        if (!address) {
            setENS('')
        } else {
        }
    }, [address])

    const onClickSubmit = React.useCallback(async () => {
        if (!ens || !address || !connector) return
        try {
            setLoading(true)
            const { data } = await apiClient.get('/nonce', {
                params: {
                    address
                }
            })

            const messageData = {
                issuer: 'JRtlKAljmbWD0BX9V7ZF',
                event_id: 'oOg8XFqIagM8oqXagFkr',
                nonce: data.nonce,
                ens
            }

            const message = JSON.stringify(messageData)
            signMessage({ message })
        } catch (err) {
            setLoading(false)
        }
    }, [ens, address, connector, signMessage])

    return React.useMemo(() => {
        if (!isConnected) {
            return (
                <SignInTemplate.Component
                    onClickConnect={onClickConnect}
                    dialogOpen={dialogOpen}
                    onCloseDialog={onSelectConnectionOrDialogClose}
                    selectedConnectionType={''}
                />
            )
        } else {
            return (
                <MainTemplate.Component
                    address={address}
                    ens={ens}
                    qr={qr}
                    onSubmit={onClickSubmit}
                    loading={loading || false}
                    onChangeENS={onChangeENS}
                />
            )
        }
    }, [
        address,
        dialogOpen,
        ens,
        isConnected,
        loading,
        onClickSubmit,
        onSelectConnectionOrDialogClose,
        qr
    ])
}

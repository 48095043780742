import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import wcIcon from '../wc.svg'
import metamaskIcon from '../mm.svg'

type ConnectionType = 'MetaMask' | 'WalletConnect' | ''

export interface WalletSelectModalProps {
    open: boolean
    selectedValue: ConnectionType
    onClose: (value: ConnectionType) => void
}

export default function WalletSelectModal(props: WalletSelectModalProps) {
    const { onClose, selectedValue, open } = props

    const handleClose = () => {
        onClose(selectedValue)
    }

    const handleListItemClick = (value: ConnectionType) => {
        onClose(value)
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={'xs'}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                Connect Wallet
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItem
                    button
                    onClick={() => handleListItemClick('MetaMask')}
                    key={'MetaMask'}
                >
                    <ListItemAvatar>
                        <Avatar src={metamaskIcon} />
                    </ListItemAvatar>
                    <ListItemText primary={'MetaMask'} />
                </ListItem>
            </List>
        </Dialog>
    )
}
